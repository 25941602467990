import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { Popup, Button, Col, Row, Dialog } from 'vant'

const app = createApp(App)
app.use(router)
app
  .use(Popup)
  .use(Button)
  .use(Col).use(Row).use(Dialog)

app.mount('#app')
