<template>
  <div>
    <router-view />
  </div>
</template>

<style>
* {
  padding: 0;
  margin: 0;
}

ul {
  list-style-type: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
